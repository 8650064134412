@import "./variables";

.padding-xs {
  padding: $whitespaceXs;
}

.padding-sm {
  padding: $whitespaceSm;
}

.padding-md {
  padding: $whitespaceMd;
}
  
.padding-lg {
  padding: $whitespaceLg;
}

.padding-xl {
  padding: $whitespaceXl;
}

.padding-horizontal-xs {
  padding: 0 $whitespaceXs;
}

.padding-horizontal-sm {
  padding: 0 $whitespaceSm;
}

.padding-horizontal-md {
  padding: 0 $whitespaceMd;
}

.padding-horizontal-lg {
  padding: 0 $whitespaceLg;
}

.padding-horizontal-xl {
  padding: 0 $whitespaceXl;
}

.padding-vertical-xs {
  padding: $whitespaceXs 0;
}

.padding-vertical-sm {
  padding: $whitespaceSm 0;
}

.padding-vertical-md {
  padding: $whitespaceMd 0;
}

.padding-vertical-lg {
  padding: $whitespaceLg 0;
}

.padding-vertical-xl {
  padding: $whitespaceXl 0;
}

.padding-bottom-xs {
  padding-bottom: $whitespaceXs;
}