// Variable overrides

$white: #fff;
$mostlyWhite: #f9f9f9;
$black: #1c1c1c;
$lightGray: #d3d3d3;
$pureBlack: #000;
$red: #d90327;
$darkGray: #777777;
$vividBlue: #005db7;
$yellow: #ffea00;
$orange: #ff6a00;

$fontSizeSmaller: 14px;
$fontSizeSmall: 16px;
$fontSizeBase: 18px;
$fontSizeLarge: 20px;
$fontSizeLarger: 24px;

$whitespaceXs: 14px;
$whitespaceSm: 18px;
$whitespaceMd: 24px;
$whitespaceLg: 32px;
$whitespaceXl: 44px;

$screenXs: 0px;
$screenSm: 600px;
$screenMd: 960px;
$screenLg: 1280px;
$screenXl: 1920px;