@import "./variables";

.text-base {
  font-size: $fontSizeBase;
}

.text-small {
  font-size: $fontSizeSmall;
}

.text-smaller {
  font-size: $fontSizeSmaller;
}

.text-large {
  font-size: $fontSizeLarge;
}

.text-larger {
  font-size: $fontSizeLarger;
}

.text-bold {
  font-weight: bold;
}