@import "variables";

// Here you can add other styles
.loader-backdrop-wrapper {
  position: relative;
  min-height: 120px;

  &--full {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.loader-backdrop {
  background: grey;
  opacity: .8;
  position: absolute;
  top: -2px;
  bottom: -2px;
  right: -2px;
  left: -2px;
  z-index: 1;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;

  .loader-backdrop-wrapper > & {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
}

.loader {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid;
    border-color: blue transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  &--small {
    width: 36px;
    height: 36px;
  }

  &--big {
    width: 80px;
    height: 80px;
  }

  &-text {
    font-size: $fontSizeSmall;
    font-weight: bold;
  }

  &--white{
    &::after {
      border-color: white transparent;
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer {
  color: #3c4b64;
  background: #ebedef;
  border-top: 1px solid #d8dbe0;
  display: flex;
  flex: 0 0 50px;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  justify-content: right;
}

.text-overflow {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-20 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-body {
  // deprecate v4
  // fix footer position to avoid breaking change.
  display: flex;
  flex-direction: column;
  // ---

  flex-grow: 1;
}

.c-wrapper {
  transition: margin 0.3s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
}

.rst__rowLabel {
  padding-right: 0 !important
}

.ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
  overflow: hidden !important;
}

.b-grid-cell {
  padding: 0.5rem !important;
}

.b-toolbar.b-dock-bottom {
  margin: auto;
}

.load-more-button {
  color: #005db7 !important;
}

.b-react-portal-container {
  width: 100%;
}
