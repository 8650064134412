@import "./variables";

.tab-content {
  border: 1px solid $mostlyWhite;
  background-color: $white;
  padding: 1rem;
}

.nav {
  &.nav-tabs {
    background-color: $mostlyWhite;
    border-color: $lightGray;
  }

  .nav-item {
    .nav-link {
      color: #1c1c1c;
      padding: 1.5em 2em;
      border-bottom: 1px solid transparent;

      &:hover {
        border: none;
        border-bottom: 1px solid $vividBlue;
        color: $vividBlue;
      }

      &.active {
        font-weight: bold;
        background-color: $white;
        border: none;
        border-bottom: 1px solid $vividBlue;
        color: $vividBlue;
        border-top: 1px solid $lightGray;
        border-right: 1px solid $lightGray;
        border-left: 1px solid $lightGray;
      }
    }
  }
}

.form-input {
  background-color: $white;
  font-size: $fontSizeSmall;

  &.error {
    background-color: rgba($color: $red, $alpha: 0.1);
  }
}
