@import "./mixins";
@import "./variables";

.shadow {
  @include box-shadow();
}

.red {
  color: $red;

  &-important {
    color: $red !important;
  }
}

.vivid {
  color: $vividBlue;

  &-important {
    color: $vividBlue !important;
  }
}

.black {
  color: $black;
}

.border {
  border: 1px solid $lightGray;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: 6px;
}

.bg-white {
  background-color: $white;
}

.bg-mostly-white {
  background-color: $mostlyWhite;
}

.red-link {
  color: $red;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $red;
  }
}

.vivid-link {
  color: $vividBlue;
  text-decoration: underline;
  cursor: pointer;

  & > * {
    color: $vividBlue;
  }

  span {
    color: $vividBlue;
  }

  &:hover {
    text-decoration: none;
    color: $vividBlue;
  }
}

.section-spacer {
  margin-bottom: 4.444em;
}

.no-wrap {
  white-space: nowrap;
}

.required {
  &::after {
    content: "*";
    color: $red;
  }
}

.hr-light-gray {
  border-color: $lightGray;
}

.disabled-link {
  color: $darkGray;
  text-decoration: underline;
  cursor: pointer;

  & > * {
    color: $darkGray;
  }

  span {
    color: $darkGray;
  }

  &:hover {
    text-decoration: none;
    color: $darkGray;
  }
}
