// If you want to override variables do it here
@import "variables";
@import "mixins";
@import "helpers";
@import "flexbox";
@import "whitespaces";
@import "typography";

@import "./theme";
// If you want to add something do it here
@import "custom";

// Variables
$tpLogoBlueLight: #72c8e0 !default;

// Bryntum overrides
.b-panel-header {
  background-color: $tpLogoBlueLight !important;
}

html,
body {
  font-size: $fontSizeSmall;
  color: $black;
  background-color: $mostlyWhite;
}

.c-body {
  background-color: $mostlyWhite;
}

.breadcrumb {
  li {
    & > a {
      color: $vividBlue;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: $vividBlue;
      }
    }
  }
}

button:focus {
  outline: none !important;
}

.c-sidebar {
  background: #00549e !important
}

span {
  color: $black;
}

.strong {
  font-weight: bold;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  z-index: 100 !important;
  width: 100% !important;
}

.react-dropdown-tree-select .dropdown {
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  width: 100%;
}

.pdf {
  width: 100%;
  height: 80vh;
}
